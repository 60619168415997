import React, {useEffect} from "react";
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import toast from 'react-hot-toast';
import {existOperation,existDevelopment, getMessageWhatsapp, getPhonesText, makeItemsNav} from '../helpers/helper.rendering'
import { useLocation } from "@reach/router";

import {newSletterTokko,updateFields} from '../redux/contactDucks'

import { Helmet } from "react-helmet"
import { getPropertiesAction, REDUX_UPDATE_FILTERS } from "../redux/propertiesDucks";

const Footer = ({filters,api_key,curFilters,developments,development,property,novelties,dispatch}) => {

  const [open,setOpen] = useState(false)
  const isBrowser = typeof window !== "undefined"
  const location = useLocation()
  const pathname = location.pathname.split("/")[1]

  const [flag,setFlag] = useState(false)

  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        template_version
        design  {
          icons
        }
        logo
        global_email
        fiscal_data
        bot_cliengo
        global_whatsapp
        global_phone
        global_broker_id
        keys{
            captcha
        }
        social{
          facebook
          instagram
          linkedin
          youtube
          twitter
        }
        branch_office{
          name
          address
          city
          region
          broker_id
          contact {
            phone
            mail
            whatsapp
          }
        }
        sections {
          footer {
              title_contact
              title_social
              title_newsletter
              title_office
              button_contact {
                  link
                  value
              }
          }
        }
      }
  }`) 

  const [closeWhatsapp,setCloseWhatsapp] = useState(true)

  const { design: { icons } } = realEstate
  const { template_version } = realEstate
  const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''
  const MediaPro = template_version === 'PRO' ? true : false;
  const [disabled,setDisabled] = useState(true)
  const [datos, setDatos] = useState({
    email: "",
  });
  const handleInputChange = (e) => {
      setDatos({
        ...datos,
        [e.target.name]: e.target.value,
      });
  };
  const sendForm = (e) => {
    e.preventDefault();
    dispatch(newSletterTokko())
    document.getElementById("formNewsletter").reset();
    return toast.success("Te suscribiste a nuestro newsletter correctamente.") 
  }
  // Función que devuelve el año actual
    
  useEffect(() => {
    const update = () => {
        dispatch(updateFields(datos));
    };
    update();
    if(!realEstate?.keys?.captcha){
      setDisabled(false)
    }
    else{
        setDisabled(true)
    }
  }, [datos]);

  const callCliengo = () => {
    if(!flag){

      var ldk = document.createElement('script');
      ldk.type = 'text/javascript';
      ldk.async = true;
      ldk.src = realEstate.bot_cliengo;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ldk, s);
      setFlag(true)
    }
  }

  const checkActive = (_pathname) => {
    if(_pathname === 'inicio' && pathname === ''){return true}
    if((curFilters.operation === "alquiler-temporario" && _pathname === 'alquiler temp.')){
        return true
    }
    if((_pathname === 'venta' || _pathname === "alquiler" && _pathname !== 'alquiler temp.') && pathname.includes('venta') || pathname.includes('alquiler')){
        if(curFilters.operation === 'alquiler' && _pathname === 'alquiler'){
            return true;
        }
        if(curFilters.operation === 'venta' && _pathname === 'venta'){
            return true;
        }
    }
    else{
        if(pathname === (_pathname)){
            return true;
        }
    }
    return false
  }

  const staticBody = () => {
    var body = document.body;
    body.classList.remove("overflow-hidden");
  }

  const fillFilters = (item) => {
    return {
        location: [],
        type: [],
        operation: item,
        environments:'',
        bedrooms:'',
        price:{
          type:'',
          priceMin:'',
          priceMax:''
        },
        surface:{
          type:'',
          surfaceMin:"",
          surfaceMax:"",
        },
        environments_types: [],
        generals: [],
        services: [],
        specials: [],
      }
  }

  const get_whatsApp_element = () => {
    if(MediaPro){
      if(realEstate.branch_office.length === 0){
        return(
            <>
              <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + realEstate.branch_office[0].contact.whatsapp[0].replaceAll("-",'')} target="_blank" class={"icon-whatsapp contact-btn d-none d-lg-flex "}></a>
              <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + realEstate.branch_office[0].contact.whatsapp[0].replaceAll("-",'')} target="_blank" class={"icon-whatsapp contact-btn d-flex d-lg-none "}></a>
            </>
        ) 
      }
      else{
        return(
          <>
          <div onClick={() => setCloseWhatsapp(!closeWhatsapp)} class={"icon-whatsapp contact-btn d-none d-lg-flex div-whatsapp " + (closeWhatsapp ? 'close' : 'open')}>
            <div className="float-whatsapp">
              {
                realEstate.branch_office.slice(0,2).map((element,index) => (
                  <>
                    <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + element.contact.whatsapp[0].replaceAll("-",'')} target="_blank" class={"contact-btn d-none d-lg-flex "}>{element.name}</a>
                    <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + element.contact.whatsapp[0].replaceAll("-",'')} target="_blank" class={"contact-btn d-flex d-lg-none "}>{element.name}</a>
                  </>
                ))
              }
            </div>
          </div>
          </>
        )
      }
    }
    else{
      <>
        <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + realEstate.global_whatsapp.replaceAll("-",'')} target="_blank" class={"icon-whatsapp contact-btn d-none d-lg-flex "}></a>
        <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + realEstate.global_whatsapp.replaceAll("-",'')} target="_blank" class={"icon-whatsapp contact-btn d-flex d-lg-none "}></a>
      </>
    }
  }

    return realEstate.sections && pathname !== "contacto" ? (
      <>
        {isBrowser && 
          <Helmet>
            {
              realEstate.bot_cliengo ? 
                callCliengo()
                :
                  ''
              }
          </Helmet>
        }
        <div className="sup-footer">
          
            <div className="container-fluid">
              <div class={"fixed-bar position-fixed " + (realEstate.global_whatsapp === '' && !MediaPro && 'd-none')}>
                {get_whatsApp_element()}
                  {/* <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + realEstate.global_whatsapp.replaceAll("-",'')} target="_blank" class={"icon-whatsapp contact-btn d-none d-lg-flex "}></a>
                  <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development,property,location?.href) + "&phone=+54" + realEstate.global_whatsapp.replaceAll("-",'')} target="_blank" class={"icon-whatsapp contact-btn d-flex d-lg-none "}></a> */}
              </div>
              <div className="row">
                <div className="col-12">
                    <div className="row">
                      <div className="col-lg-4 order-1">
                        <div className="item-contact row mb-lg-0">
                            <h5 className="col-lg-12 col-4">{realEstate?.sections?.footer?.title_contact}</h5>
                            <div className="col-lg-12 col-8">
                              <a target="_blank" className="hover-footer-link mb-0 mb-lg-0" href={"mailto:"+realEstate?.global_email}>{realEstate?.global_email}</a>
                              {(realEstate?.global_phone)?.map(phone =>(
                                <p className={"mb-0 mb-lg-0 " + (phone === '' ? 'd-none' : '')}>Teléfono {phone}</p>
                              ))}
                              {realEstate?.global_broker_id.map((broker,index) => (
                                <p className={"mb-0 mb-lg-0 " +(broker === ' ' && "d-none")}>{broker}</p>
                              ))}
                              
                              {/* <a target="_blank" className="hover-footer-link mb-5" href={"https://api.whatsapp.com/send?phone=+549" + (realEstate.whatsapp.replaceAll("-",""))}>Whatsapp {realEstate.whatsapp}</a> */}
                              <Link className="d-none d-lg-none btn btn-blue mt-5" to={realEstate?.sections?.footer?.button_contact.link}>{realEstate?.sections?.footer?.button_contact.value}</Link>
                              <Link className="btn btn-blue d-lg-inline-block d-none mt-5" to={realEstate?.sections?.footer?.button_contact?.link}>{realEstate?.sections?.footer.button_contact.value}</Link>
                            </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-xxl-3 d-none d-lg-block order-2">
                        {realEstate.branch_office.length > 0 &&
                          <div className="item-contact row d-none d-lg-block">
                              <h5  className="col-lg-12 col-4">{realEstate.sections?.footer?.title_office} {" "}{realEstate.branch_office[0].name}</h5>
                              <div className="col-lg-12 col-8">
                                <p >{realEstate.branch_office[0].address} </p>
                                <p >{realEstate.branch_office[0].region}</p>
                                <a target="_blank" className={"hover-footer-link "  + (!realEstate.branch_office[0].contact.mail && 'd-none')} href={"mailto:"+realEstate.branch_office[0].contact.mail}>{realEstate.branch_office[0].contact.mail}</a>
                                {realEstate.branch_office[0].contact.phone.map(phone => (
                                  <p >Teléfono {phone}</p>
                                ))}
                                {realEstate.branch_office[0].contact.whatsapp.map(whatsapp => (
                                  <a className={"hover-footer-link"} target="_blank" href={"https://api.whatsapp.com/send?" + "phone=54" + whatsapp.replaceAll("-",'')}  >Whatsapp + {whatsapp}</a>
                                ))}
                                <p className={realEstate.branch_office[0].broker_id === '' && 'd-none'}>Matrícula {realEstate.branch_office[0].broker_id}</p>
                              </div>
                          </div>
                        }
                      </div>
                      <div className="col-lg-4 offset-xxl-1 pl-lg-4 order-3 d-none d-lg-block">
                        <div className="item-contact row mb0">
                            <h5  className="col-lg-12 col-4">{realEstate?.sections?.footer?.title_social}</h5>
                            <div className="col-lg-12 col-8">
                              <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class={`icon-instagram${iconsStyle}`}></i></a>
                              <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class={`icon-facebook${iconsStyle}`}></i></a>
                              <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class={`icon-linkedin${iconsStyle}`}></i></a>
                              <a target="_blank" href={realEstate.social.youtube} class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class={`icon-youtube${iconsStyle}`}></i></a>
                              <a target="_blank" href={realEstate.social.twitter} class={realEstate.social.twitter ? "d-inline" : 'd-none'}><i class={`icon-twitter${iconsStyle}`}></i></a>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="col-lg-4">
                  <div></div>
                </div>
                <div className="col-lg-4 col-xxl-3 ">
                  {realEstate.branch_office.length > 0 &&
                    realEstate.branch_office.map((branch,index) => (
                          <div className={"item-contact row " + (index > 0 ? 'd-block' : 'd-lg-none d-flex')}>
                              <h5  className="col-xxl-9 col-lg-12 col-4">{realEstate.sections?.footer?.title_office} {" "} {branch.name}</h5>
                              <div className="col-xxl-9 col-lg-12 col-8">
                                <p >{branch.address}</p>
                                <p >{branch.region}</p>
                                <a target="_blank" className={"hover-footer-link " + (!branch.contact.mail && 'd-none')} href={"mailto:"+branch.contact.mail}>{branch.contact.mail}</a>
                                {branch.contact.phone.map(phone => (
                                  <p >Teléfono {phone}</p>
                                ))}
                                {branch.contact.whatsapp.map(whatsapp => (
                                  <a className={"hover-footer-link"} target="_blank" href={"https://api.whatsapp.com/send?" + "phone=54" + whatsapp.replaceAll("-",'')}  >Whatsapp + {whatsapp}</a>
                                ))}
                                <p className={branch.broker_id === '' && 'd-none'}>Matrícula {branch.broker_id}</p>
                              </div>
                          </div>
                    ))
                  }
                </div>
                <div className="d-block d-lg-none">
                  <div className="item-contact row mb0">
                      <h5  className="col-lg-12 col-4">{realEstate?.sections?.footer?.title_social}</h5>
                      <div className="col-lg-12 col-8">
                        <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class={`icon-instagram${iconsStyle}`}></i></a>
                        <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class={`icon-facebook${iconsStyle}`}></i></a>
                        <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class={`icon-linkedin${iconsStyle}`}></i></a>
                        <a target="_blank" href={realEstate.social.youtube} class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class={`icon-youtube${iconsStyle}`}></i></a>
                        <a target="_blank" href={realEstate.social.twitter} class={realEstate.social.twitter ? "d-inline" : 'd-none'}><i class={`icon-twitter${iconsStyle}`}></i></a>
                      </div>
                  </div>
                </div>
                <div className="col-lg-4 offset-xxl-1">
                    <div className="content-contact mt-auto">
                      <h4 >{realEstate.sections?.footer?.title_newsletter}</h4>
                      <form onSubmit={sendForm} id="formNewsletter" >
                        <div className="d-flex mb-lg-3">
                            <input type="email" onChange={handleInputChange} name="email" required placeholder="Ingresá tu mail" className="mr-2" />
                            <button
                             disabled={disabled}
                            type="submit" className="submit icon-arrow"></button>
                        </div>
                        {
                            realEstate?.keys?.captcha ? 
                            <ReCAPTCHA
                            sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                            onChange ={() => setDisabled(false)}
                            /> 
                            : 
                            '' 
                        }
                      </form>
                    </div>
                </div>
              </div>
            </div>      
        </div>
        <footer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-2 d-flex d-lg-block align-items-start flex-column">
                      <div className="logo-footer mx-auto mx-lg-0">
                        <Link to=""><img src={realEstate.logo}></img></Link> 
                        {/* CAMBIAR POR REALESTATE.LOGO */}
                      </div>
                        
                    </div>
                    <div className="col-5 col-lg-8 d-lg-flex d-none align-items-center justify-content-evenly">
                        {makeItemsNav(developments,filters,novelties).map((item,index) => (
                          <li onClick={() => staticBody() + ((item.name.toLowerCase() === 'venta' || item.name.toLowerCase() === 'alquiler') ? dispatch(REDUX_UPDATE_FILTERS(fillFilters(item.name),item.name)) + setOpen(false) + dispatch(getPropertiesAction()) : dispatch(REDUX_UPDATE_FILTERS(fillFilters("venta"),"venta")))} className={(checkActive(item.name.toLowerCase()) ? 'active' : '')}><Link onClick={() => staticBody() + setOpen(false)} className="" to={item.link}>{item.name} </Link></li>
                        ))}
                    </div>
                    <div className="col-2 d-lg-flex d-none" style={{justifyContent:"flex-end", alignContent:"center"}}>
                      <div className="" style={{right: 0, bottom: 0}}>
                        {realEstate?.fiscal_data ?
                          <a className="qr d-flex justify-content-end" href={realEstate?.fiscal_data} target="_F960AFIPInfo"><img src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" /></a>
                          :
                          ''
                        }
                      </div>
                    </div>
                    <div className="col-12 mt-5 d-lg-none">
                      <div className="row">
                        <div className="col-6">
                          {makeItemsNav(developments,filters,novelties).map((item,index) => (
                              index < 5 && 
                                <li className={(pathname === item.link.replaceAll("/","").toLowerCase() ? 'active ' : '')}><Link onClick={() => staticBody() + setOpen(false)} className="" to={item.link}>{item.name} </Link></li>
                          ))}
                        </div>
                        <div className="col-6 d-flex flex-column">
                          {makeItemsNav(developments,filters,novelties).map((item,index) => (
                              index >= 5 && 
                                <li className={(pathname === item.link.replaceAll("/","").toLowerCase() ? 'active ' : '')}><Link onClick={() => staticBody() + setOpen(false)} className="" to={item.link}>{item.name} </Link></li>
                          ))}
                          <div className="mt-auto d-block d-lg-none mt-auto">
                                {
                                  realEstate?.fiscal_data ? 
                                    <a className="qr d-flex justify-content-start" href={realEstate?.fiscal_data} target="_F960AFIPInfo"><img src={"http://www.afip.gob.ar/images/f960/DATAWEB.jpg"} border="0" /></a>
                                    
                                  :
                                    ''
                                }
                          </div>
                        </div>
                      </div>
                    </div>
            </div>
            <div className="d-flex align-items-start d-lg-none text-center justify-content-center mt-5">
              <p className="copy">
                Designed  by <a target="_blank" href="https://mediahaus.com.ar"> MediahausLite</a> - Powered by <a target="_blank" href="https://mediacore.app/"> MediaCore</a><br />
                <span style={{ fontSize: "0.7rem" }} >Todos los derechos reservados ® 2022</span>
                {/* Plataforma <a href="https://mediacore.app/sesion/inicio/"> MediaCore<span style={{fontSize: "0.5rem", verticalAlign: "super"}}>® </span> </a> by <a href="https://mediahaus.com.ar">MediaHaus<span style={{fontSize: "0.5rem", verticalAlign: "super"}}>® </span></a> {getCurrentYear()}. Todos los derechos reservados. */}
              </p>
            </div>
                <div className="row py-lg-5 d-none d-lg-flex justify-content-between align-items-end">
                      <div className="col-lg-12 col-12 text-center justify-content-center d-lg-flex align-items-start">
                        <p className="copy">
                        Designed  by <a target="_blank" href="https://mediahaus.com.ar"> MediahausLite</a> - Powered by <a target="_blank" href="https://mediacore.app/"> MediaCore</a><br />
                        <span style={{fontSize: "0.7rem"}} >Todos los derechos reservados ® 2022</span>
                        {/* Plataforma <a href="https://mediacore.app/sesion/inicio/"> MediaCore<span style={{fontSize: "0.5rem", verticalAlign: "super"}}>® </span> </a> by <a href="https://mediahaus.com.ar">MediaHaus<span style={{fontSize: "0.5rem", verticalAlign: "super"}}>® </span></a> {getCurrentYear()}. Todos los derechos reservados. */}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
      </>
    ):''
}

export default connect(state => ({
  filters:state.properties.totalListFilters,
  curFilters:state.properties.filters,
  api_key:state.settings.keys.tokko,
  developments:state.developments.developments,
  novelties:state.novelties.grid_novelties,
  property:state.properties.property,
  development:state.developments.development
}),null)(Footer);